import React, { useEffect, useState } from "react";
import "../../css/style.css"
import "../../css/leftmenu.css"
import { Redirect, Link, useLocation } from 'react-router-dom';
import { toast, Toaster } from "react-hot-toast"
import AuthService from "../authentication/AuthService";
import UserPreferences from "../settings/UserPreferences";
import axios from "axios";

export default function LeftMenu() {
  const [version, setVersion] = useState("");
  const [userAvatar, setUserAvatar] = useState("");
  const [needUpgrade, setNeedUpgrade] = useState(false);
  const headers_whop =  {
    "Content-Type": "application/json",
    "front-api-key": "Bearer ZGEG",
  }
  const headers =  {
    'ngrok-skip-browser-warning': 'skip',
    'bypass-tunnel-reminder': 'skip',
    'x-user-provided-key': UserPreferences.getServerPassword(),
    'Content-type': 'application/json',
    'Accept': 'application/json'
  }

  function compareVersions(version1, version2) {
      const v1 = version1.split('.').map(Number);
      const v2 = version2.split('.').map(Number);

      for (let i = 0; i < 3; i++) {
          if (v1[i] < v2[i]) {
              return false;
          } else if (v1[i] > v2[i]) {
              return true;
          }
      }

      return false;
  }
  async function refreshUser() {
    axios
      .get("https://r2ebd2n2rlt5fgfkmdoqs6hpga0mdtfw.lambda-url.eu-west-1.on.aws/memberships/" + AuthService.getLicense(), { headers: headers_whop })
      .then((res) => {
        var element = res.data
        if (element["license_key"] === AuthService.getLicense()) {
          setVersion(element.metadata.version)
          if (element.discord.image_url === ""){
            setUserAvatar(require("../../assets/logo.png"))
          }
          else {
            setUserAvatar(element.discord.image_url)
          }
          if(!compareVersions(element.metadata.version,"2.18.0")){
            setNeedUpgrade(true)
          }
          console.log(userAvatar)
        }
        
      })
      .catch((e) => {
        return console.log(`fetching issue due to ${e}`);
      });
  }
  const routeName = useLocation();
  const urlname = routeName.pathname.replace('/', '');
  const Toasting = () => {
    toast.success('Successfully toasted!', {
      position: 'bottom-center',
      style: {
        background: "black",
        color: "white",
        border: "1px solid #1F2023"
      }
    });
  }
  useEffect(() => {
    refreshUser()
    // eslint-disable-next-line
  },[])
  return (
    <div className="side-bar">
      <div className="side-logo">
        <h2>
          {' '}
          <img src={require('../../assets/logo.png')} /> AgorAIO{' '}
        </h2>
      </div>
      <ul>
        <li>
          {' '}
          <Link to="/dashboard" className={`${urlname == 'dashboard' || urlname == '' && 'active'}`}>
            {' '}
            <img src={require('../../assets/home-a.png')} />  Home {' '}
          </Link>
          
        </li>
        <li>
          {' '}
          <Link to="/profile" className={`${urlname == 'profile' && 'active'}`}>
            {' '}
            <img src={require('../../assets/profiles-a.png')} /> Profiles
          </Link>
        </li>
        <li>
          {' '}
          <Link to="/task" className={`${urlname == 'task' && 'active'}`}>
            {' '}
            <img src={require('../../assets/task-a.png')} />   Tasks
          </Link>
        </li>

        <li>
          {' '}
          <Link to="/account" className={`${urlname == 'account' && 'active'}`}>
            {' '}
            <img src={require('../../assets/proxies-a.png')} />  Proxies  {' '}
          </Link>
        </li>
        <li>
          {' '}
          <Link to="/history" className={`${urlname == 'history' && 'active'}`}>
            {' '}
            <img src={require('../../assets/log-a.png')} /> Log History    {' '}
          </Link>
        </li>
        <li>
          {' '}
          <Link to="/settings" className={`${urlname == 'settings' && 'active'}`}>
            {' '}
            <img src={require('../../assets/setting-a.png')} /> Settings    {' '}
          </Link>
        </li>
      </ul>
      <div className="up_pos">
        <div className="upgrade">
        <div className="left-menu-text-with-image">
          <div className="left-menu-image-container">
            <img className="left-menu-image" src={userAvatar} alt=""></img>
          </div>
        <div className="left-menu-text">
          <h5 style={{paddingLeft:"8px"}}>Membership active</h5>  
          
          </div>
        </div>
          
        

          {/* <div className="progress  bg-blur">
            <div className="progress-bar bg-light" role="progressbar" style={{ width: '75%' }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
          </div> */}

          {/* <div className="progress_lbl">
            <label> 23 Days left</label>
            <label className="hx">Upgrade</label>
          </div> */}
          {
            !needUpgrade && (
              <div className="version">
                <h4>Up to date</h4>
                <img src={require('../../assets/upgrade.png')} />
                <p> Current Version: {version} </p>
              </div>
            )
          }
          {
            needUpgrade && (
              <div className="version">
                <h4>Need Upgrade</h4>
                <img src={require('../../assets/need-upgrade.png')} />
                <p> Current Version: {version} </p>
              </div>
            )
          }
        </div>
        <div className="guide_btns">
          <button><a href="https://lpa.gitbook.io/agoraio" target="_blank"> Guides </a> </button>
          <button><a href="https://whop.com/hub/" target="_blank" > Whop Dashboard </a> </button>
        </div>
      </div>
      <Toaster />
    </div>
  );
}
