import React, { useEffect, useState } from 'react';
import '../css/accounts.css';
import '../css/style.css';
import LeftMenu from '../components/common/LeftMenu';
import Header from '../components/common/Header';
import axios from "axios";
import DeleteProxy from '../components/DeleteProxy';
import PatchProxy from '../components/PatchProxy';
import toast from 'react-hot-toast';
import UserPreferences from "../components/settings/UserPreferences"
import { toastError, toastSuccess } from '../components/common/toastUtils';

export default function Accounts() {
  const [proxyList, setProxyList] = useState([])
  const [isAllChecked, setIsAllChecked] = useState(false)
  const [proxies, setProxies] = useState([]);
  const [editProxies, setEditProxies] = useState("");
  const [isProxiesEmpty, setIsProxiesEmpty] = useState(false);
  const [proxypools, setProxypools] = useState([]);
  const [isProxypoolEmpty, setIsProxypoolEmpty] = useState(false);
  const [isLoadingTest, setIsLoadingTest] = useState(false);
  const [selectedProxypool, setSelectedProxypool] = useState();
  const [proxypoolsCounts, setProxypoolCounts] = useState({});

  const headers = {

    'ngrok-skip-browser-warning': 'skip',
    'bypass-tunnel-reminder': 'skip',
    'x-user-provided-key': UserPreferences.getServerPassword(),
    'Content-type': 'application/json'
  }


  useEffect(() => {
    getproxyData();
  }, []);

  useEffect(() => {
  }, [proxyList])

  const addProxyToList = (value) => {
    setProxyList(prevStates => ({
      ...prevStates,
      [value]: !prevStates[value]
      })
    )
    console.log(proxyList)
  }
  function deleteProxypoolProxies(){
    axios
      .put(UserPreferences.getServerUrl() + "/proxypools/" + selectedProxypool.id, {"label":selectedProxypool.label,"proxies":[]}, { headers: headers })
        .then((res) => {
          if (res.data === "error") {
            toastError(res.data.msg)
          } else {
            getproxyData()
            toastSuccess(res.data.msg)

          }
        })
        .catch((err) => 
          toastError(err)
        );
  }
  const handleAllProxy = () => {
    setIsAllChecked(!isAllChecked)
    Object.entries(proxies).forEach(res => {
      setProxyList(prevStates => ({
        ...prevStates,
        [res[0]]: !isAllChecked
      }))
    })
  }

  function getproxyData() {
    axios
      .get(UserPreferences.getServerUrl() + "/proxypools", { headers: headers })
      .then((res) => {
        setProxypools(res.data)
        calculateProxypoolCounts(res.data)
        if (res.data.length === 0){
          setIsProxypoolEmpty(true)
          setIsProxiesEmpty(true)
        }
        else {
          if (res.data.length >= 0 && activeIndex === null){
            setProxies(res.data[0].enriched_proxies)
            setEditProxies(res.data[0].proxies.join("\n"))
            setActiveIndex(0)
            setSelectedProxypool(res.data[0])
            if(res.data[0].proxies.length === 0){
              setIsProxiesEmpty(true)
            }
            else {
              setIsProxiesEmpty(false)
            }
          }
          else if (activeIndex !==null){
            setSelectedProxypool(res.data[activeIndex])
            setProxies(res.data[activeIndex].enriched_proxies)
            setEditProxies(res.data[activeIndex].proxies.join("\n"))
            if(res.data[activeIndex].proxies.length === 0){
              setIsProxiesEmpty(true)
            }
            else {
              setIsProxiesEmpty(false)
            }
          }
        }
      })
      .catch((e) => {
        return console.error(`fetching issue due to ${e}`);
      });
  }


  const calculateProxypoolCounts = (data) => {
    const counts = {};
    data.forEach((proxypool) => {
      const proxyPoolLabel = proxypool.label;
      counts[proxyPoolLabel] = proxypool.proxies.length
    });
    setProxypoolCounts(counts);
  }

  const [data, setData] = useState({

    label: "",
    proxies: ""
  });



  const handleInputs = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    setData((prev) => ({ ...prev, [key]: value }));
  };

  const [newProxypoolName, setNewProxypoolName] = useState("");
  const handleProxypoolNameInput = (event) => {
    setNewProxypoolName(event.target.value)
  };
  const [newProxypoolProxies, setNewProxypoolProxies] = useState("");
  const handleProxypoolProxiesInput = (event) => {
    setNewProxypoolProxies(event.target.value)
  };
  const handleProxypoolProxiesEdit = (event) => {
    setEditProxies(event.target.value)
  };

  const [activeIndex, setActiveIndex] = useState(null);

  const handleGroupClick = (index) => {
    setIsAllChecked(false)
    setProxyList([])
    setActiveIndex(index === activeIndex ? null : index);
    setSelectedProxypool(proxypools[index])
    if (proxypools[index].proxies.length === 0){
      setIsProxiesEmpty(true)
    }
    else {
      setIsProxiesEmpty(false)
    }
    setProxies(proxypools[index].enriched_proxies)
    setEditProxies(proxypools[index].proxies.join("\n"))
  }



  const submitProxy = (event) => {
    event.preventDefault()
    axios
      .post(UserPreferences.getServerUrl() + "/proxypools", {"label":newProxypoolName,"proxies":newProxypoolProxies.split("\n")}, { headers: headers })
      .then((res) => {
        if (res.data === "error") {

          toast.error('Something went wrong', {
            position: 'bottom-center',
            style: {
              background: "black",
              color: "white",
              border: "1px solid #1F2023",
              borderRadius: 20,
              autoClose: 3000,
            }
          });
        } else {
          getproxyData()
          toastSuccess("Proxypool created")

          // window.location.reload();



        }
      })
      .catch((err) => {
        toastError(err)
      })
  }

  const patch = (event) => {
    event.preventDefault()    
      axios
      .put(UserPreferences.getServerUrl() + "/proxypools/" + selectedProxypool.id, {"label":selectedProxypool.label,"proxies":editProxies.split("\n")}, { headers: headers })
        .then((res) => {
          if (res.data === "error") {
  
            toast.error('Something went wrong', {
              position: 'bottom-center',
              style: {
                background: "black",
                color: "white",
                border: "1px solid #1F2023",
                borderRadius: 20,
                autoClose: 3000,
              }
            });
          } else {
            getproxyData()
            toast.success(res.data.msg, {
              position: 'bottom-center',
              style: {
                background: "black",
                color: "white",
                border: "1px solid #1F2023",
                borderRadius: 20,
                autoClose: 3000,
              }
            });

          }
        })
        .catch((err) => toast.error(`Something went wrong due to ${err} `, {
          position: 'bottom-center',
          style: {
            background: "black",
            color: "white",
            border: "1px solid #1F2023",
            autoClose: 3000,
          }
        }));
    }

  const [target, setTarget] = useState("");
  const handleTargetChange = (event) => {
    setTarget(event.target.value)
  }
  function testProxypool() {
    setIsLoadingTest(true)
    axios
      .post(UserPreferences.getServerUrl() + "/proxypools/" + proxypools[activeIndex]?.id + "/test", { "target": target }, { headers: headers })
      .then((res) => {
        setIsLoadingTest(false)
        if (res.data === "error") {
          toastError("Something went wrong")
        } else {
          setProxies(res.data.proxypool)
          toastSuccess("Operation success")

        }
      })
      .catch((err) => toast.error(`Something went wrong due to ${err} `, {
        position: 'bottom-center',
        style: {
          background: "black",
          color: "white",
          border: "1px solid #1F2023",
          autoClose: 3000,
        }
      }));
  }
  function getProxyStatusClassName(label){
    if (label === "not tested"){
      return "idle"
    }
    else if (label === "error"){
      return "failed"
    }
    else{
      return "success"
    }
  }
  const tableRows = Object.entries(proxies).map(([key, value], index) => (
    <>
      <tr className="row_height"> </tr>
      <tr>
        {/* <td>
          <label className="form-group1">
            <input onChange={() => addProxyToList(key.substring(0,80))} checked={proxyList[key.substring(0,80)]} type="checkbox" id={"html2" + index + 1 } />
            <label htmlFor={"html2" + index + 1 }></label>
            <span>{` ${index + 1}`}</span>
          </label>
        </td> */}
        <td>{` ${index + 1}`}</td>
        <td>{key.substring(0,90)}</td>
        <td> { isLoadingTest === true ? (<img style={{height:'20px'}} src={require("../assets/loading.gif")} />) : (<span className={getProxyStatusClassName(value)}>{value}</span>) } </td>
        <td class="right_ac">
        <PatchProxy proxy_to_delete={key} proxypool_id={proxypools[activeIndex]?.id} proxies={proxypools[activeIndex]?.proxies} label={proxypools[activeIndex]?.label} callback={getproxyData}/>

          <div className="box_pro" data-bs-toggle="modal" data-bs-target={"#Patch_Proxy_Modal" + proxypools[activeIndex]?.id}><img src={require('../assets/del.png')} className="profile-image-hover" /></div>

        </td>
      </tr>
    </>
  ));

  return (
    <div className="accounts">
      <div className="main-container">
        <LeftMenu />
        <div className="right-side">
          <Header />

          <div className="middle-section-container flex">
            <div className="middle-section border-right-2 profile_middle">
              <div className="profile_item1">
                <div className="col-lg-2 user-info">
                  <h2>{proxypools[activeIndex]?.label || "No proxypool"}</h2>
                  <h5> <span> {proxypools[activeIndex]?.proxies?.length || 0} </span> </h5>
                </div>
                <div className="col-lg-4 padding-left-12">
                  <div className="profile_inputsearch">
                    <input type="text" placeholder="Enter your target site" onChange={handleTargetChange} />
                  </div>
                </div>
                <div className="col-lg-2 padding-left-12 test_btn" onClick={testProxypool}>
                  <button className="gren"> Test All <img src={require('../assets/play.png')} onClick={testProxypool} /> </button>
                </div>
                <div className="col-lg-2 padding-left-12 test_btn" onClick={deleteProxypoolProxies}>
                  <button className="pink"> Delete All <img src={require('../assets/del.png')} onClick={deleteProxypoolProxies} /> </button>
                </div>
                <div className="col-lg-2 create_right">
                  <button className="active-btn" data-bs-toggle="modal" data-bs-target="#AddProxies">
                    Add Proxies{' '}
                    <img src={require('../assets/images/plus.png')} />{' '}
                  </button>{' '}
                </div>
              </div>
              <div className="profile_table">
                <div className="col-lg-12 padding-left-right-0">
                  <div className="margin-top-16 table-scrolling table_scrolling_proxies padding-right-5 no_proxydata">
                    <table className="ab-table-border-2ed" cellPadding="0" border="0">
                      <thead>
                        <tr className="tr-th">
                          {/* <th>
                            <div className="form-group1">
                              <input onChange={handleAllProxy} checked={isAllChecked} type="checkbox" id="html0" />
                              <label htmlFor="html0"></label>
                              ID
                            </div>
                          </th> */}
                          <th>ID</th>
                          <th>Proxy </th>
                          <th>Speed</th>
                          <th className="right_ac">Actions</th>
                        </tr>
                      </thead>
                      <tbody>

                        {tableRows}
                      </tbody>
                    </table>
                      {
                        isProxiesEmpty && (
                        <div className="No_data task_nodata">
                          {' '} <img src={require('../assets/no-proxy.png')} />{' '}
                          <h2> You have no proxies yet</h2>
                          <p>Add some proxies to get started!</p>
                        </div>

                        )
                        }
                  </div>

                </div>
              </div>
            </div>

            <div className="right-sidebar proxy_poolsh2">
              <h2>Proxy Pools </h2>
              <button className="addproxy" data-bs-toggle="modal" data-bs-target="#AddProxyPool">Add Proxies Pool
                <img src={require('../assets/plus.png')} />
              </button>
              <div className="btn_br"> </div>
              
              <div className="proxies_group_pool">
                {
                  proxypools?.map((item, index) => {
                    return <>
                      <div className={`group-task ${index === activeIndex ? 'active_group' : ''}`} onClick={() => handleGroupClick(index)} key={index}>
                        <div className="flex">
                          <div className="left_profile">
                            <img
                              src={require('../assets/server.png')}
                              className="profile-image-hover"
                            />
                          </div>
                          <div className="right_pro">
                            <h5>{item.label}</h5>
                            <h4> <span>{proxypoolsCounts[item.label]} Proxies</span></h4>
                          </div>
                          <DeleteProxy id={item?.id} callback={getproxyData} />
                          <div className="prox_dl">
                            <div className="box_pro">
                            <div className="box_pro" data-bs-toggle="modal" data-bs-target={"#Delete_Proxy_Modal" + item.id} >
                              <img src={require('../assets/del.png')} className="proxy_hover" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  })
                }

              {
                isProxypoolEmpty && (
              <div className="No_data proxyNN">
                {' '} <img src={require('../assets/no-proxypool.png')} />{' '}
                <h2> You have no proxy pools yet </h2>
                <p>Create some pools to get started!</p>
              </div>

                )

              }
              </div>


            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="AddProxyPool" tabIndex="-1" data-bs-backdrop="true" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-margin">
          <div className="modal-content">
            <div className="modal-header padding-15">
              <h5 className="modal-title white font-size-15 font-weight-600" id="exampleModalLabel">
                Add Proxy Pool
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">Close</button>
            </div>
            <form className="modal-body padding-15" method='post'>
              <div className="margin-bottom-15">
                <div className="popup-left-selects">
                  <label className="white font-size-13 font-weight-400"> Proxy Pool Name</label>
                  <input type="text" placeholder="Enter Proxy Pool Name" className="setting-input" onChange={handleProxypoolNameInput}  />
                </div>
              </div>
              <div className="margin-bottom-15">
                <div className="popup-left-selects">
                  <label className="white font-size-13 font-weight-400"> Proxy List</label>
                  <div className="proxy_list">
                    <textarea type="text" placeholder="Enter Proxies" onChange={handleProxypoolProxiesInput} />
                  </div>
                </div>
              </div>
              <div className="margin-bottom-15 margin-top-30 text-left">
                <button onClick={submitProxy} type="submit" className="addProxybtn" data-bs-dismiss="modal" data-bs-target="#Create_Profile_Modal">
                  Add Proxy Pool{' '} <img src={require('../assets/images/plus.png')} />{' '} </button>{' '}
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="modal fade" id="AddProxies" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-margin">
          <div className="modal-content">
            <div className="modal-header padding-15">
              <h5 className="modal-title white font-size-15 font-weight-600" id="exampleModalLabel">
                Edit Proxies
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">Close</button>
            </div>

              {/* <div className="flex margin-bottom-15">

                <div className="popup-right-selects width-50 right">
                  <label className="white font-size-13 font-weight-400"> Proxy Label</label>
                  <input type="text" placeholder="Enter Profile Name" name='label' value={data.label} onChange={handleInputs} className="setting-input" />
                </div>
                <div className="popup-right-selects width-50 right">
                  <label className="white font-size-13 font-weight-400"> Proxy</label>
                  <input type="text" placeholder="Enter Profile Name" name='proxies' value={data.proxies} onChange={handleInputs} className="setting-input" />
                </div>
              </div> */}

  <form className="modal-body padding-15" method='post'>
                <div className="margin-bottom-15">
                  <div className="popup-left-selects">
                    <label className="white font-size-13 font-weight-400"> Proxy List</label>
                    <div className="proxy_list">
                      <textarea type="text" placeholder="Enter Proxies" onChange={handleProxypoolProxiesEdit} value={editProxies}/>
                    </div>
                  </div>
                </div>
                <div className="margin-bottom-15 margin-top-30 text-left">
                  <button onClick={patch} type="submit" className="addProxybtn" data-bs-dismiss="modal" data-bs-target="#AddProxies_Modal">
                    Edit Proxies{' '} <img src={require('../assets/images/plus.png')} />{' '} </button>{' '}
                </div>
              </form>
          </div>
        </div>
      </div>
    </div>
  );
}
